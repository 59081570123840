// @flow
/* istanbul ignore file */

import { isPopupBlockerEnabled, hasAdBlock } from 'lib/browser-extension'
import loadingContent from './loading.html'
import MESSAGES from 'quick-quote/constants/messages'
import { base64ToBlob } from 'core/service/lib/tobase64'

export const openNewWindowWithLoading = (): window => {
  const foundAdBlock = hasAdBlock()
  let newBrowserWindow = window.open('', '_blank')
  const foundPopupBlocker = isPopupBlockerEnabled(newBrowserWindow)
  if (foundPopupBlocker && foundAdBlock) {
    throw new Error(MESSAGES.BOTH_ADBLOCK_POPUP_DETECTED)
  }
  if (foundPopupBlocker) throw new Error(MESSAGES.POPUP_BLOCKER_ENABLED)
  if (foundAdBlock) {
    newBrowserWindow.close()
    throw new Error(MESSAGES.ADBLOCK_DETECTED)
  }
  newBrowserWindow.document.body.innerHTML = loadingContent
  newBrowserWindow.document.title = MESSAGES.LOADING
  return newBrowserWindow
}

export const openPdf = (pdfBase64: string, newBrowserWindow: typeof window): Promise<*> => {
  if (!window.cordova) {
    return openBase64InBrowser(pdfBase64, newBrowserWindow)
  }

  return openPdfForMobile(pdfBase64)
}

const openPdfForMobile = (pdfBase64: string): Promise<*> => {
  const fileName = 'quickquote.pdf'

  const createPdfBlob = base64ToBlob(pdfBase64)
  const prepareFile = requestFs().then(getFile(fileName))

  return Promise.all([createPdfBlob, prepareFile])
    .then(([blob, file]) => writeFile(blob, file))
    .then(openFileInNativeView)
}

const openBase64InBrowser = (pdfBase64: string, newBrowserWindow: typeof window): Promise<*> => {
  const createPdfBlob = base64ToBlob(pdfBase64)
  return createPdfBlob.then((blob) => {
    const fileURL = URL.createObjectURL(blob)
    newBrowserWindow.location.href = fileURL
  })
}

const requestFs = () =>
  new Promise(
    (resolve, reject) =>
      // disable LocalFileSystem lint and flow error
      /* eslint-disable */
      window.requestFileSystem(
        window.LocalFileSystem.PERSISTENT,
        0,
        (fs) => resolve(fs),
        (error) => reject(error)
      )
    /* eslint-enable */
  )

type File = {
  createWriter: Function,
  nativeURL: string,
}

const getFile = (fileName: string) => (fs: *): Promise<File> =>
  new Promise((resolve, reject) => {
    const options = { create: true, exclusive: false }
    fs.root.getFile(
      fileName,
      options,
      (file) => resolve(file),
      (error) => reject(error)
    )
  })

const writeFile = (data: Blob, file: File): Promise<*> =>
  new Promise((resolve, reject) => {
    file.createWriter((writer) => {
      writer.onwriteend = () => resolve(file)
      writer.onerror = (error) => reject(error)

      writer.write(data)
    })
  })

const openFileInNativeView = (file: *): Promise<void> =>
  new Promise((resolve, reject) => {
    try {
      window.cordova.plugins.fileOpener2.open(file.nativeURL, 'application/pdf')
      resolve()
    } catch (error) {
      reject(error)
    }
  })
